<template>
  <div v-if="isshowgrid">
    <va-card title="Operator Setting List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" v-if="adminRole || createProvision" @click="add()">Add</va-button>

        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props" v-if="adminRole || createProvision">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
        <template slot="allow_notification" slot-scope="props">
          <p v-if="props.rowData.allow_notification == true">Enabled</p>
          <p v-else>Disabled</p>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <va-modal
              v-model="showPreRemoveModal"
              :title="pre_title"
              size='small'
              :message="pre_message"
              okText="Confirm"
              cancelText="Cancel"
              @ok="updateNow()"
              @cancel="cancel()">
            </va-modal>
            <span class="va-form-label va-form-required-label">Organization Name</span>
            <va-select
              v-if="isCreate"
              placeholder="Select Organization Name"
              v-model="org_name"
              textBy="org_name"
              searchable
              @input="getReseller(org_name)"
              :options="OrgArr"
              :error="!!orgErrors.length"
              :error-messages="orgErrors"
            />
            <va-input
              v-if="!isCreate && (!editFlag || isUpdate)"
              v-model="org_name"
              type="text"
              placeholder="Enter Organization Name"
              :error="!!orgErrors.length"
              :error-messages="orgErrors"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">Language</span>
            <multiselect
              v-if="isCreate || isUpdate"
              placeholder="Select Language"
              v-model="language"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :hide-selected="false"
              :taggable="false"
              track-by="id"
              id="example"
              :multiple="true"
              label="language"
              :options="languageArr"
            />
            <div v-if="((org_type =='HEPI_OPERATOR' || org_type == 'HEPI_RESELLER') && isCreate) || ((org_type =='HEPI_OPERATOR' || org_type == 'HEPI_RESELLER') && isUpdate)">

              <span class="va-form-label va-form-required-label">Bundle</span>
              <va-select
                placeholder="Select Bundle"
                v-model="bundle"
                textBy="bundle_name"
                searchable
                id="example"
                track-by="id"
                @input="getMoqBundle(bundle)"
                :options="bundleArr"
              />
            </div>
            <va-modal v-model="showConfirmationModal"  title="Confirm Bundle Update"
              message="The old bundle will get deactivated and the newly added bundle should get activated for all the active subscriptions with the existing end date."
              @ok="updateNow()"
              no-outside-dismiss
              okText="Proceed"
              cancelText="No"
            >
            </va-modal>
            <div v-if="((org_type =='OPERATOR' || org_type == 'RESELLER') && isCreate) || ((org_type =='OPERATOR' || org_type == 'RESELLER') && isUpdate)">
              <span class="va-form-label va-form-required-label">Bundle</span>

              <multiselect
                placeholder="Select Bundle"
                v-model="bundle"
                multiple="true"
                label="bundle_name"
                id="example"
                :close-on-select="false"
                :clear-on-select="true"
                :preserve-search="true"
                :hide-selected="false"
                :taggable="false"
                track-by="id"
                @input="getMoqBundle(bundle)"
                :options="bundleArr"
              />
            </div>

            <div
              v-if="bundleErrors.length > 0"
              class="errors">
              {{bundleErrors[0]}}
            </div>
            <span class="va-form-label">Add on Bundle</span>
            <multiselect
              v-if="isCreate || isUpdate"
              v-model="addonbundle"
              placeholder="Select Addon Bundle"
              label="bundle_name"
              track-by="id"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :hide-selected="false"
              :taggable="false"
              id="example"
              :multiple="true"
              @input="checkBundleArray(addonbundle)"
              :options="addonbundleArr"
            />
            <span class="va-form-label va-form-required-label">Edge Domain</span>
            <va-input
              v-model.trim="feed_domain"
              type="text"
              pattern="^[A-Za-z0-9-_.:]+(?: +[A-Za-z0-9-_.:]+)*$"
              placeholder="Enter Edge Domain"
              :error="!!feedDomainErrors.length"
              :error-messages="feedDomainErrors"
            />
            <span class="va-form-label va-form-required-label">App Signature</span>
            <va-input
              v-if="isCreate || (isUpdate && editFlag)"
              v-model.trim="package_name"
              type="text"
              pattern="^[A-Za-z0-9.]+(?: +[A-Za-z0-9]+)*$"
              placeholder="Enter App Signature"
              :error="!!appSignatureErrors.length"
              :error-messages="appSignatureErrors"
            />
            <va-input
              v-if="!editFlag && isUpdate"
              v-model="package_name"
              type="text"
              pattern="^[A-Za-z0-9.]+(?: +[A-Za-z0-9]+)*$"
              placeholder="Enter App Signature"
              :error="!!appSignatureErrors.length"
              :error-messages="appSignatureErrors"
              :disabled="true"
            />
            <div v-if="smsFlag">
              <u><b>SMS Details</b></u><br />
              <span class="va-form-label va-form-required-label">Request Type</span>
              <div class="row" style="padding-left: 10px;">
                <va-radio-button
                  option="http"
                  v-model="request_type"
                  label="HTTP"
                />
                <va-radio-button
                  option="https"
                  v-model="request_type"
                  label="HTTPS"
                />
              </div>
              <span class="va-form-label va-form-required-label">SMS Host</span>
              <va-input
                v-if="isCreate || (isUpdate && editFlag)"
                v-model.trim="sms_host"
                type="text"
                pattern="^[A-Za-z0-9-_.:]+(?: +[A-Za-z0-9-_.:]+)*$"
                placeholder="Enter SMS Host"
                :error="!!smsHostErrors.length"
                :error-messages="smsHostErrors"
              />
              <va-input
                v-if="!editFlag && isUpdate"
                v-model="sms_host"
                type="text"
                pattern="^[A-Za-z0-9-_.:]+(?: +[A-Za-z0-9-_.:]+)*$"
                placeholder="Enter SMS Host"
                :error="!!smsHostErrors.length"
                :error-messages="smsHostErrors"
                :disabled="true"
              />
              <span class="va-form-label va-form-required-label">SMS Method</span>
              <va-select
                v-if="isCreate || (isUpdate && editFlag)"
                placeholder="Select SMS Method"
                v-model="sms_method"
                textBy="id"
                :options="smsMethodArr"
                :error="!!smsMethodErrors.length"
                :error-messages="smsMethodErrors"
              />
              <va-input
                v-if="!editFlag && isUpdate"
                placeholder="Select SMS Method"
                v-model="sms_method"
                :error="!!smsMethodErrors.length"
                :error-messages="smsMethodErrors"
                :disabled="true"
              />
              <span class="va-form-label va-form-required-label">SMS Contact Key</span>
              <va-input
                v-if="isCreate || (isUpdate && editFlag)"
                v-model.trim="sms_property"
                type="text"
                pattern="^[A-Za-z0-9-_.:]+(?: +[A-Za-z0-9-_.:]+)*$"
                placeholder="Enter SMS Contact Key"
                :error="!!smscontactErrors.length"
                :error-messages="smscontactErrors"
              />
              <va-input
                v-if="!editFlag && isUpdate"
                v-model="sms_property"
                type="text"
                pattern="^[A-Za-z0-9-_.:]+(?: +[A-Za-z0-9-_.:]+)*$"
                placeholder="Enter SMS Contact Key"
                :error="!!smscontactErrors.length"
                :error-messages="smscontactErrors"
                :disabled="true"
              />
              <span class="va-form-label va-form-required-label">SMS API Context</span>
              <va-input
                v-if="isCreate || (isUpdate && editFlag)"
                v-model.trim="sms_api_context"
                type="text"
                pattern="^[A-Za-z0-9-_.:]+(?: +[A-Za-z0-9-_.:]+)*$"
                placeholder="Enter SMS API Context"
                :error="!!smsApiErrors.length"
                :error-messages="smsApiErrors"
              />
              <va-input
                v-if="!editFlag && isUpdate"
                v-model="sms_api_context"
                type="text"
                pattern="^[A-Za-z0-9-_.:]+(?: +[A-Za-z0-9-_.:]+)*$"
                placeholder="Enter SMS API Context"
                :error="!!smsApiErrors.length"
                :error-messages="smsApiErrors"
                :disabled="true"
              />
              <span class="va-form-label va-form-required-label">SMS Template Key</span>
              <va-input
                v-if="isCreate || (isUpdate && editFlag)"
                v-model.trim="sms_template_key"
                type="text"
                pattern="^[A-Za-z0-9-_.:]+(?: +[A-Za-z0-9-_.:]+)*$"
                placeholder="Enter SMS API Context"
                :error="!!smsTemplateKeyErrors.length"
                :error-messages="smsTemplateKeyErrors"
              />
              <va-input
                v-if="!editFlag && isUpdate"
                v-model.trim="sms_template_key"
                type="text"
                pattern="^[A-Za-z0-9-_.:]+(?: +[A-Za-z0-9-_.:]+)*$"
                placeholder="Enter SMS API Context"
                :error="!!smsTemplateKeyErrors.length"
                :error-messages="smsTemplateKeyErrors"
              />
              <div class="flex xs12">
                <label class="va-form-label va-form-required-label">Message Template</label><span class="va-form-label"> (Mention the Name as **** and OTP as xxxx)</span>
                <textarea v-if="isCreate || (isUpdate && editFlag)" class="text_box" v-model="sms_template" />
                <textarea v-if="!editFlag && isUpdate" class="text_box" v-model="sms_template" :disabled ="true" />
              </div>
              <label>Custom Fields</label>
              <div class="fields"  v-for="(field,i) in customFields" :key="i">
                <va-input
                  v-if="isCreate || (isUpdate && editFlag)"
                  placeholder="Enter Key"
                  v-model.trim="field.key"
                  required="true"
                />
                <va-input
                  v-if="!editFlag && isUpdate"
                  placeholder="Enter Key"
                  v-model="field.key"
                  :disabled="true"
                />
                <va-input
                  v-if="isCreate || (isUpdate && editFlag)"
                  placeholder="Enter Value"
                  v-model.trim="field.value"
                  required="true"
                />
                <va-input
                  v-if="!editFlag && isUpdate"
                  placeholder="Enter Value"
                  v-model="field.value"
                  :disabled="true"
                />
                <va-button
                  v-if="isCreate || (isUpdate && editFlag)"
                  class="content_width toCenter"
                  flat color="primary"
                  @click.prevent="fields_length++"
                  icon="entypo entypo-plus" />
                <va-button
                  v-if="(customFields.length != 1) && (isCreate || (isUpdate && editFlag))"
                  class="content_width toCenter"
                  flat color="danger"
                  @click.prevent="removeField(i, 'custom')"
                  icon="entypo entypo-cancel" />
              </div>
            </div>
            <va-checkbox
              v-model="allow_notification"
              label="Notify On First Login"
            />
            <va-checkbox
              v-if="operatorFlag && adminRole"
              v-model="allow_operator_content"
              label="Allow Operator Content"
            />
            <va-checkbox
              v-if="operatorFlag && !adminRole"
              v-model="allow_operator_content"
              label="Allow Operator Content"
              :disabled="true"
            />
            <span v-if="allow_operator_content && operatorFlag" class="va-form-label va-form-required-label">Reference Name</span>
            <va-input
              v-if="allow_operator_content && operatorFlag && adminRole"
              v-model.trim="reference_name"
              type="text"
              pattern="^[a-z0-9-A-Z-_.:]+(?: +[a-z0-9-_.:]+)*$"
              placeholder="Enter Reference Name"
              :error="!!referenceNameErrors.length"
              :error-messages="referenceNameErrors"
            />
            <va-input
              v-if="allow_operator_content && operatorFlag && !adminRole"
              v-model.trim="reference_name"
              type="text"
              :disabled="true"
            />
            <va-checkbox
              v-model="allow_essentials"
              label="Display Full Catalog"
            />
            <span v-if="allow_essentials" class="va-form-label va-form-required-label">Msg to Activate Essential</span>
            <va-input
              v-if="allow_essentials"
              v-model.trim="essential_msg"
              type="text"
              placeholder="Enter Message"
              :error="!!essentialMsgErrors.length"
              :error-messages="essentialMsgErrors"
            />
            <span v-if="checkIptv.m2mFlag" class="va-form-label va-form-required-label">NCF (Exclusive of GST)</span>
            <va-input
              v-if="checkIptv.m2mFlag"
              v-model="ncf"
              type="number"
              min=0
              placeholder="Enter NCF"
              :error="!!ncfErrors.length"
              :error-messages="ncfErrors"
            />
            <va-checkbox
              v-if="(Role == 'ADMIN' || Role == 'RESELLER' ||  Role =='HEPI_RESELLER') && enableOpFlag"
              v-model="enable_bundle_creation"
              label="User SelfCare App"
            />
            <va-checkbox
              v-if="Role == 'ADMIN' && ifResellerFlag && !enable_res_flag"
              v-model="enable_reseller_bundle_creation"
              label="Enable Reseller Bundle Creation"
            />
            <va-checkbox
              v-if="Role == 'ADMIN' && ifResellerFlag && enable_res_flag"
              v-model="enable_reseller_bundle_creation"
              label="Enable Reseller Bundle Creation"
              :disabled="true"
            />
            <va-checkbox
              v-if="(Role == 'RESELLER' ||  Role == 'HEPI_RESELLER') && ifResellerFlag"
              v-model="enable_reseller_bundle_creation"
              label="Enable Custom Bundle Creation"
              :disabled="true"
            />
            <va-checkbox
              v-if="Role == 'ADMIN' && !operatorFlag && enable_reseller_bundle_creation"
              v-model="is_fixed_rate"
              label="IPTV Fixed Rate"
            />
            <div v-if="Role == 'ADMIN' || Role == 'RESELLER' || Role == 'HEPI_RESELLER' ">
              <va-checkbox
                v-model="external_apps"
                label="External Apps"
                @input="getAllowSubscription(external_apps)"
              />
              <va-checkbox
                v-if="external_apps"
                v-model="allow_for_subscription"
                label="Allow For Subscription"
              />
<!--               <u><b v-if="isCreate">Technical</b></u><br />
              <u><b v-if="isUpdate">Technical</b></u><br />
              <div class="fields"  v-for="(field,i) in technicalFields" :key="i">
                <va-select
                  v-if="isCreate"
                  placeholder="Select Provider"
                  v-model="field.provider"
                  textBy="brand"
                  :options="provider_list"
                />
                <va-input
                  v-if="isUpdate && field.showFlag"
                  placeholder="Enter Upstream"
                  v-model.trim="field.brand"
                  required="true"
                />
                <va-select
                  v-if="isUpdate && !field.showFlag"
                  placeholder="Select Provider"
                  v-model="field.provider"
                  textBy="brand"
                  :options="provider_list"
                />
                <va-input
                  v-if="isCreate || (isUpdate && editFlag)"
                  placeholder="Enter Upstream"
                  v-model.trim="field.upstream"
                  required="true"
                />
                <va-input
                  v-if="!editFlag && isUpdate"
                  placeholder="Enter Upstream"
                  v-model="field.upstream"
                  :disabled="true"
                />
                <va-input
                  v-if="isCreate || (isUpdate && editFlag)"
                  placeholder="Enter Failover Upstream"
                  v-model.trim="field.failover_upstream"
                  required="true"
                />
                <va-input
                  v-if="!editFlag && isUpdate"
                  placeholder="Enter Failover Upstream"
                  v-model="field.failover_upstream"
                  :disabled="true"
                />
                <va-button
                  v-if="isCreate || (isUpdate && editFlag)"
                  class="content_width toCenter"
                  flat color="primary"
                  @click.prevent="technical_fields_length++"
                  icon="entypo entypo-plus" />
                <va-button
                  v-if="(technicalFields.length != 1)"
                  class="content_width toCenter"
                  flat color="danger"
                  @click.prevent="removeTechField(i)"
                  icon="entypo entypo-cancel" />
              </div>
 -->            </div>
            <div v-if="enable_bundle_creation || enable_reseller_bundle_creation">
              <u><b v-if="enable_bundle_creation || enable_reseller_bundle_creation">Payment</b></u><br>
              <span class="va-form-label va-form-required-label">Api for Get Payment</span>
              <va-input
                v-if="Role == 'ADMIN'"
                v-model="api_get_payment_link"
                type="text"
                placeholder="Enter Api for Get Payment"
                :error="!!apiGetPayErrors.length"
                :error-messages="apiGetPayErrors"
              />
              <va-input
                v-if="Role == 'RESELLER' || Role == 'HEPI_RESELLER'"
                v-model="api_get_payment_link"
                type="text"
                :disabled="true"
              />
              <span class="va-form-label va-form-required-label">Api for Payment Status</span>
              <va-input
                v-if="Role == 'ADMIN'"
                v-model="api_payment_link_status"
                type="text"
                placeholder="Enter Api for Payment Status"
                :error="!!apiPayStatusErrors.length"
                :error-messages="apiPayStatusErrors"
              />
              <va-input
                v-if="Role == 'RESELLER' || Role == 'HEPI_RESELLER'"
                v-model="api_payment_link_status"
                type="text"
                :disabled="true"
              />
              <span class="va-form-label va-form-required-label">Payment Fields</span>
              <div class="fields" v-for="(field,i) in paymentFields" :key="i">
                <va-input
                  v-if="isCreate || (isUpdate && editFlag)"
                  placeholder="Enter Key"
                  v-model.trim="field.key"
                  required="true"
                />
                <va-input
                  v-if="!editFlag && isUpdate"
                  placeholder="Enter Key"
                  v-model="field.key"
                  :disabled="true"
                />
                <va-input
                  v-if="isCreate || (isUpdate && editFlag)"
                  placeholder="Enter Value"
                  v-model.trim="field.value"
                  required="true"
                />
                <va-input
                  v-if="!editFlag && isUpdate"
                  placeholder="Enter Value"
                  v-model="field.value"
                  :disabled="true"
                />
                <va-button
                  v-if="isCreate || (isUpdate && editFlag)"
                  class="content_width toCenter"
                  flat color="primary"
                  @click.prevent="payment_fields_length++"
                  icon="entypo entypo-plus" />
                <va-button
                  v-if="(paymentFields.length != 1) && (isCreate || (isUpdate && editFlag))"
                  class="content_width toCenter"
                  flat color="danger"
                  @click.prevent="removePaymentField(i)"
                  icon="entypo entypo-cancel" />
              </div>
            </div>
            <u><b>MOQ Slab</b></u><br/>
            <span class="va-form-label va-form-required-label">MoQ Slab Name</span>
            <va-input
              v-model="moq_slab_name"
              type="text"
              placeholder="Enter MoQ Slab Name"
              :error="!!moqSlabNameErrors.length"
              :error-messages="moqSlabNameErrors"
            />
            <span class="va-form-label va-form-required-label">MOQ Duration</span>
            <va-select
              placeholder="Select MOQ Duration"
              v-model="moq_duration"
              searchable
              textBy="duration"
              :options="moqDurationArr"
              :error="!!moqDurationErrors.length"
              :error-messages="moqDurationErrors"
            />
            <span class="va-form-label va-form-required-label">MoQ Cost</span>
            <va-input
              v-model="moq_cost"
              type="number"
              min="1"
              placeholder="Enter MoQ Cost"
              :error="!!moqCostErrors.length"
              :error-messages="moqCostErrors"
            />
            <label v-if="(moq_slab_list.length > 0)"><u><b>MOQ Slab List</b></u></label>
            <div class="fields"  v-if="(moq_slab_list.length > 0)" >
              <span >MoQ Bundle</span>
              <span >MoQ Duration</span>
              <span >Start Date</span>
              <span >End Date</span>
              <span >MoQ Cost</span>
            </div>
            <div class="fields"  v-if="(moq_slab_list.length > 0)"  v-for="(field,i) in moq_slab_list" :key="i" >
              <va-input
                v-model.trim="field.moq_name"
                :disabled="true"
              />
              <va-input
                v-model.trim="field.duration"
                :disabled="true"
              />
              <va-input
                v-model.trim="field.start_date.split('T')[0]"
                :disabled="true"
              />
              <va-input
                v-model.trim="field.end_date.split('T')[0]"
                :disabled="true"
              />
              <va-input
                v-model.trim="field.cost"
                :disabled="true"
              />
            </div>
            <va-checkbox
              v-if="moq_carry_flag"
              v-model="moq_carry_forward"
              label="MoQ Carry Forward"
            />
            <div v-if="iswhatsappno">
              <span class="va-form-label va-form-required-label">Whatsapp Number</span>
              <va-input
                v-model.trim="phone_no"
                type="text"
                maxlength=10
                placeholder="Enter Phone Number"
                :error="!!phoneNoErrors.length"
                :error-messages="phoneNoErrors"
                required="true"
              />
            </div>
            <div style="display: flex;" v-if = "(org_type =='HEPI_OPERATOR' && isCreate) || (org_type =='HEPI_OPERATOR' && isUpdate)">
              <div>
                <div v-if="(org_type === 'HEPI_OPERATOR' && (isCreate || isUpdate))" style="display: flex;">
                  <div>
                    <span class="va-form-label va-form-required-label">Service Date</span>
                    <datepicker
                      style="margin-top: -17px;"
                      class="mt-2"
                      inputClass="input"
                      v-model="service_date"
                      format="MMM dd yyyy"
                      placeholder="MM-DD-YYYY"
                      @input="billingDateCheck()"
                      :disabledDates="disabledDate"
                    />
                  </div>
                  <div class="ml-5">
                    <span class="va-form-label va-form-required-label">Billing Date (Billing date should be greater than Service date)</span>
                    <datepicker
                      style="margin-top: -17px;"
                      class="mt-2"
                      inputClass="input"
                      v-model="billing_date"
                      format="MMM dd yyyy"
                      placeholder="MM-DD-YYYY"
                      @input="billingDateCheck()"
                      :disabledDates="disabledBillingDates"
                    />
                  </div>
                </div>
              </div>
            </div> <br>
            <div style="display: flex;" v-if = "(org_type =='HEPI_OPERATOR' && isCreate) || (org_type =='HEPI_OPERATOR' && isUpdate)">
              <div>
                <span class="va-form-label va-form-required-label">STB Counts</span>
                <va-input
                  v-if="(org_type =='HEPI_OPERATOR' && isCreate) || (org_type =='HEPI_OPERATOR' && isUpdate)"
                  v-model="stb_count"
                  type="number"
                  placeholder="Enter STB Counts"
                  oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                  :error="!!stbCountErrors.length"
                  :error-messages="stbCountErrors"
                />
              </div>
              <div class="ml-5">
                <span class="va-form-label va-form-required-label">Invoice Due Day</span>
                <va-input
                  v-if="(org_type =='HEPI_OPERATOR' && isCreate) || (org_type =='HEPI_OPERATOR' && isUpdate)"
                  v-model="due_day"
                  type="number"
                  placeholder="Enter Invoice Due Date"
                  oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                  :error="!!dueDateErrors.length"
                  :error-messages="dueDateErrors"
                />
              </div>
            </div>
            <va-checkbox
              v-if="Role == 'ADMIN' && operatorFlag && org_type != 'HEPI_OPERATOR'"
              v-model="pre_activation_flag"
              label=" STB Pre-activation"
            />
            <va-checkbox
              v-if="operatorFlag && Role == 'RESELLER'"
              v-model="pre_activation_flag"
              disabled
              label=" STB Pre-activation"
            />
            <va-button
              v-if="((pre_activation_flag && Role == 'ADMIN') || (pre_activation_flag && Role == 'SUPPORT') ||(pre_activation_flag && Role == 'RESELLER'))"
              type="submit"
              @click.prevent="activationCall()"
            >Activate Bundle
            </va-button>
            <va-modal
              class="flex xs12 md12"
              v-model="preActivationModal"
              size="large"
              noEscDismiss
              noOutsideDismiss
              okText="Update"
              cancelText="Cancel"
              @ok="finish()"
              @cancel="cancel()"
            >
              <span style="font-size: 21px;"><b>Pre-Activation</b>
                <br><span style="font-size: 15px;">Note : Cost Price (Exclusive of GST)</span>
              </span>
              <slot style="margin: 50px;">
                <div class="va-table">
                  <table style="width: 100%;" class="va-table-responsive">
                    <thead>
                      <tr>
                        <th class="content-th">Bundle</th>
                        <th class="content-th">Mode</th>
                        <th class="content-th">Start Date</th>
                        <th class="content-th">End Date</th>
                        <th class="content-th">STB Allowed</th>
                        <th class="content-th">Grace Period</th>
                        <th class="content-th">Cost Price</th>
                        <th class="content-th" v-if="isUpdate">Used Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(field,i) in pre_activation" :key="field.id">
                        <td v-if="!field.removed_flag">
                          <va-select
                            v-if = "(isCreate || isUpdate) && !field.fieldFlag"
                            placeholder="Select Bundle Name"
                            v-model="field.bundle_name"
                            searchable
                            textBy="bundle_name"
                            :options="PreActivationBundleArr"
                            @click="bundleShown()"
                            noClear
                          />
                          <va-select
                            v-if = "isUpdate && field.fieldFlag"
                            placeholder="Select Bundle Name"
                            v-model="field.bundle_name"
                            searchable
                            textBy="bundle_name"
                            :options="PreActivationBundleArr"
                            @click="bundleShown()"
                            noClear
                            :disabled="true"
                          />
                        </td>
                        <td v-if="!field.removed_flag">
                          <va-select
                            v-if = "(isCreate || isUpdate) && !field.fieldFlag"
                            placeholder="Select Mode"
                            v-model="field.mode"
                            textBy="id"
                            :options="modeArr"
                            noClear
                          />
                          <va-select
                            v-if = "isUpdate && field.fieldFlag"
                            placeholder="Select Mode"
                            v-model="field.mode"
                            textBy="id"
                            :options="modeArr"
                            noClear
                            :disabled="true"
                          />
                        </td>
                        <td v-if="!field.removed_flag">
                          <datepicker
                            style="margin-top: -17px;"
                            v-if="(isCreate || isUpdate) && !field.fieldFlag"
                            inputClass="input"
                            v-model="field.start_date"
                            format="MMM dd yyyy"
                            placeholder="MM-DD-YYYY"
                            @input="dateCheck()"
                            :disabledDates="disabledDate"
                          />
                          <va-input
                            v-if="isUpdate && field.fieldFlag"
                            v-model="field.start_date.split('T')[0]"
                            :disabled="true"
                            style="width: 90px;"
                          />
                        </td>
                        <td v-if="!field.removed_flag">
                          <va-input
                            v-if="(isCreate || isUpdate)"
                            v-model="field.end_date"
                            :disabled="true"
                            style="width: 90px;"
                          />
                        </td>
                        <td v-if="!field.removed_flag">
                          <va-input
                            v-if="(isCreate || isUpdate) && !field.fieldFlag"
                            placeholder="Enter Value"
                            type="number"
                            min=1
                            v-model="field.free_count"
                            oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                          />
                          <va-input
                            v-if="isUpdate && field.fieldFlag"
                            v-model="field.free_count"
                            :disabled="true"
                          />
                        </td>
                        <td v-if="!field.removed_flag">
                          <va-input
                            v-if="(isCreate || isUpdate) && !field.fieldFlag"
                            placeholder="Enter Value"
                            type="number"
                            min=0
                            oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                            v-model="field.grace_period"
                          />
                          <va-input
                            v-if="isUpdate && field.fieldFlag"
                            v-model="field.grace_period"
                            :disabled="true"
                          />
                        </td>
                        <td v-if="!field.removed_flag">
                          <va-input
                            v-if="(isCreate || isUpdate) && !field.fieldFlag"
                            type="text"
                            placeholder="Enter Value"
                            min="0"
                            oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'')"
                            v-model="field.pre_bundle_cost"
                          />
                          <va-input
                            v-if="isUpdate && field.fieldFlag"
                            v-model="field.pre_bundle_cost"
                            oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'')"
                            :disabled="true"
                          />
                        </td>
                        <td v-if="!field.removed_flag">
                          <va-input
                            v-if="isUpdate"
                            v-model="field.used_count"
                            :disabled="true"
                          />
                        </td>
                        <td v-if="!field.removed_flag && !supportRole && Role == 'ADMIN'">
                          <va-button
                            v-if="!check_active_pre_activation"
                            class="content_width toCenter"
                            flat color="danger"
                            @click.prevent="removeField(i, 'pre_activation')"
                            icon="entypo entypo-cancel"
                          />
                        </td>
                        <td v-if="!field.removed_flag && !supportRole && Role == 'ADMIN'">
                          <va-button
                            v-if="pre_activation.length"
                            class="content_width toCenter"
                            flat color="primary"
                            @click.prevent="preActivation_length++"
                            icon="entypo entypo-plus"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </slot>
            </va-modal>
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createOperatorSetting()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateOperatorSetting()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
  <div v-else-if="msgFlag">
    <va-card><center><b>{{msg}}</b></center></va-card>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
import REGEX from '../../i18n/regex_pattern'
import Datepicker from 'vuejs-datepicker'
Vue.use(vueResource)
export default {
  name: 'operator_setting',
  components: { Datepicker },
  beforeCreate () {
    var role = this.$cookies.get('userRole')
    if (role == 'RESELLER' || role == 'HEPI_RESELLER') { this.editFlag = false }
    const loader = Vue.$loading.show({ width: 40, height: 40 })
    this.$http.get(config.menu.host + '/language').then(lang_res => {
      lang_res.body.map(function (lang) {
        delete lang.logo_url
        delete lang.native
        delete lang.short_code
      })
      this.languageArr = lang_res.body
      this.$http.get(config.menu.host + '/provider').then(pro_res => {
        this.provider_list = pro_res.body
        this.provider_list.map(function (dta, i) {
          dta.id = i + 1
          dta.showFlag = false
        })
        // this.opertorSettingData = response.body
        this.isshowForm = false
        this.isshowgrid = true
        if (role == 'SUPPORT') { this.supportRole = true }
        this.$http.get(config.menu.host + '/org').then(resp => {
          this.OrgArr = resp.body
          if ((role == 'ADMIN') || (role == 'SUPPORT') || (role == 'ADVANCED_SUPPORT')) {
            var arr = []
            this.$http.get(config.menu.host + '/bundle').then(res => {
              res.body.map(function (item, i) {
                if (!item.add_on) {
                  if (item.bundle_type != 'resellerpackage') {
                    arr.push(item)
                  }
                }
              })
              this.$http.get(config.menu.host + '/operator_setting/all/new/hepi_bundle').then(newHepiBun => {
                this.newHepiBundle = newHepiBun.body.map((item, i) => {
                  item.id = i + 1
                  return item
                })
                this.bundleArrbk = [...arr, ...newHepiBun.body]
                this.bundleArr = [...arr, ...newHepiBun.body]
              })
              this.$http.get(config.menu.host + '/bundle/get/addon').then(respo => {
                var addarr = []
                var vm = this
                respo.body.map(function (item, i) {
                  if (item.bundle_type != 'resellerpackage' && item.iptv_type != 'Dpo') {
                    addarr.push(item)
                  }
                })
                this.addonbundleArrbk = addarr
                this.addonbundleArr = addarr
                loader.hide()
                this.getAllOperatorSetting()
              })
            })
          } else {
            this.$http.get(config.menu.host + '/operator_setting').then(oper => {
              // if(oper.body[0].pre_activation_flag == 0) { this.check_pre_activation = true}
              if (oper.body[0].enable_reseller_bundle_creation == 1) {
                this.$http.get(config.menu.host + '/bundle/get/reseller').then(response => {
                  var arr = []; var addarr = []
                  response.body.map(function (bundle, i) {
                    bundle.id = i + 1
                    if (bundle.reseller_bundle_type == 'Base Bundle') {
                      arr.push(bundle)
                    } else {
                      if (bundle.iptv_type != 'Dpo') {
                        addarr.push(bundle)
                      }
                    }
                  })
                  this.bundleArr = arr
                  this.bundleArrbk = arr
                  this.addonbundleArr = addarr
                  this.addonbundleArrbk = addarr
                  loader.hide()
                  this.getAllOperatorSetting()
                })
              } else {
                this.$http.get(config.menu.host + '/bundle/operator/content').then(resp => {
                  var arr = []
                  resp.body.base.map(function (item, i) {
                    arr.push(item)
                  })
                  this.bundleArr = arr
                  this.bundleArrbk = arr
                  this.$http.get(config.menu.host + '/bundle/get/addon').then(respo => {
                    var addarr = []
                    respo.body.map(function (item, i) {
                      if (item.iptv_type != 'Dpo') {
                        addarr.push(item)
                      }
                    })
                    this.addonbundleArr = addarr
                    this.addonbundleArrbk = addarr
                    loader.hide()
                    this.getAllOperatorSetting()
                  })
                }, error => {
                  loader.hide()
                  if (error && error.body) {
                    this.msgFlag = true
                    this.isshowForm = false
                    this.isshowgrid = false
                    this.msg = 'Operator setting not found. Please contact your admin!'
                  }
                })
              }
            })
          }
        })
      })
    })
  },
  data () {
    return {
      term: null,
      fields_length: 0,
      technical_fields_length: 0,
      payment_fields_length: 0,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      adminRole: false,
      showRemoveModal: false,
      bundle_name: '',
      Role: '',
      feed_domain: '',
      package_name: '',
      ad_mso_margin: '',
      ad_sys_margin: '',
      language: [],
      ad_operator_margin: '',
      bundle: [],
      addonbundle: [],
      org_name: '',
      org_type: 'HEPI_OPERATOR',
      isCreate: true,
      isUpdate: false,
      service_date: null,
      billing_date: null,
      reference_name: '',
      concurrent_login: '',
      sms_host: '',
      sms_method: '',
      sms_property: '',
      sms_template_key: '',
      sms_template: '',
      sms_api_context: '',
      smsFlag: true,
      // check_pre_activation: false,
      editFlag: true,
      createProvision: false,
      title: '',
      opertorSettingData: [],
      customFields: [{ key: '', value: '' }],
      paymentFields: [{ key: '', value: '' }],
      orgErrors: [],
      OrgArr: [],
      feedDomainErrors: [],
      ncfErrors: [],
      ncf: '',
      request_type: 'http',
      packageErrors: [],
      languageErrors: [],
      languageArr: [],
      smsTemplateKeyErrors: [],
      concurrentLoginErrors: [],
      appSignatureErrors: [],
      operatorMarginErrors: [],
      msoMarginErrors: [],
      sysMarginErrors: [],
      addonbundleArr: [],
      bundleArr: [],
      newHepiBundle: [],
      bundleErrors: [],
      moqbundle: '',
      moqbundleArr: [],
      moqbundleErrors: [],
      addonbundleErrors: [],
      smsHostErrors: [],
      smscontactErrors: [],
      smsMethodErrors: [],
      smsApiErrors: [],
      package: [],
      referenceNameErrors: [],
      allow_notification: false,
      operatorFlag: false,
      allow_operator_content: false,
      smsMethodArr: [{ id: 'GET' }, { id: 'POST' }],
      essentialMsgErrors: [],
      allow_essentials: false,
      essential_msg: '',
      technicalFields: [{ provider: '', upstream: '', failover_upstream: '' }],
      provider_list: [],
      showFlag: false,
      external_apps: false,
      allow_for_subscription: false,
      msgFlag: false,
      discount: '',
      discountErrors: [],
      enable_bundle_creation: false,
      iswhatsappno: false,
      apiGetPayErrors: [],
      api_get_payment_link: '',
      apiPayStatusErrors: [],
      api_payment_link_status: '',
      moqCostErrors: [],
      moq_cost: 1,
      moqDurationArr: [{ id: 1, duration: '30 days' }, { id: 2, duration: '90 days' }, { id: 3, duration: '180 days' }, { id: 4, duration: '360 days' }],
      moq_duration: '',
      moqDurationErrors: [],
      moqSlabNameErrors: [],
      moq_slab_name: '',
      moq_slab_list: [],
      phoneNoErrors: [],
      org_type: '',
      moq_carry_forward: false,
      moq_carry_flag: false,
      externalAppHostErrors: [],
      externalAppTokenErrors: [],
      externalAppPlanErrors: [],
      externalAppCodeErrors: [],
      bundleArrbk: [],
      addonbundleArrbk: [],
      ifResellerFlag: false,
      enable_reseller_bundle_creation: false,
      check_active_pre_activation: false,
      enableOpFlag: true,
      enable_res_flag: true,
      role_type: '',
      phone_no: '',
      pre_activation: [{
        bundle_name: '',
        mode: '',
        start_date: '',
        end_date: '',
        free_count: 1,
        grace_period: 0,
        pre_bundle_cost: '',
        used_count: 0,
        removed_flag: false,
      }],
      pre_activation_flag: false,
      preActivationModal: false,
      preActivation_length: 0,
      modeArr: [{ id: 'One Month' }, { id: 'Three Month' }, { id: 'Six Month' }, { id: 'Twelve Month' }],
      PreActivationBundleArr: [],
      pre_activation_backup: [],
      disabledDate: {
        to: null,
      },
      is_fixed_rate: false,
      del_preactivation: [],
      supportRole: false,
      showPreRemoveModal: false,
      pre_title: '',
      pre_message: '',
      check_active_pre_activation: false,
      service_date: '',
      billing_date: '',
      end_dateArr: [],
      due_day: 0,
      stb_count: '',
      dueDateErrors: [],
      stbCountErrors: [],
      showConfirmationModal: false,
      already_updated: '',
      selectedDate: null,
      packagess: [],
    }
  },
  watch: {
    fields_length () {
      this.customFields.push({ key: '', value: '' })
    },
    technical_fields_length () {
      this.technicalFields.push({ provider: '', upstream: '', failover_upstream: '' })
    },
    payment_fields_length () {
      this.paymentFields.push({ key: '', value: '' })
    },
    preActivation_length () {
      var arr = []; var final = []
      this.PreActivationBundleArr = this.pre_activation_backup
      arr = this.pre_activation.map(function (data) {
        if ((data != '') && (data.bundle_name != '') && data.bundle_name.bundle_name && !data.removed_flag) {
          return data.bundle_name.bundle_name
        };
      })
      this.PreActivationBundleArr.map(function (bundle) {
        if (arr.indexOf(bundle.bundle_name) == -1) {
          final.push(bundle)
        };
      })
      this.PreActivationBundleArr = final
      this.pre_activation.push({
        bundle_name: '',
        mode: '',
        start_date: '',
        end_date: '',
        free_count: 1,
        grace_period: 0,
        pre_bundle_cost: '',
        used_count: 0,
        removed_flag: false,
      })
      this.checkRemoval()
    },
  },
  computed: {
    disabledBillingDates () {
      if (this.service_date) {
        const currentDate = new Date(new Date().setDate(new Date(this.service_date).getDate())).toISOString().split('T')[0]
        const fiveDays = new Date(new Date().setDate(new Date(this.service_date).getDate() + 5)).toISOString().split('T')[0]
        const minDate = new Date(currentDate)
        minDate.setDate(minDate.getDate())
        const maxDate = new Date(fiveDays)
        maxDate.setDate(maxDate.getDate())
        return {
          customPredictor: date => {
            const currentDate = new Date(date)
            return currentDate < minDate || currentDate > maxDate
          },
        }
      }
      return null
    },
    disabledDate () {
      return null
    },

    checkIptv () {
      var vm = this
      var m2mFlag = false
      var id

      for (var i = 0; i < vm.bundle.length; i++) {
        if (vm.bundle[i].bundle_reseller_custom_packages && vm.bundle[i].bundle_reseller_custom_packages.length > 0) {
          vm.bundle[i].bundle_reseller_custom_packages.map(function (bundle_item) {
            if (bundle_item.iptv) {
              m2mFlag = true
            }
          })
        }
        vm.bundleArr.filter(function (total_bundle) {
          if ((vm.bundle[i].bundle_id == total_bundle.bundle_id) && (total_bundle.bundle_type == 'groupedpackage')) {
            if (total_bundle && total_bundle.bundle_grouped_packages && total_bundle.bundle_grouped_packages.length > 0) {
              total_bundle.bundle_grouped_packages.map(function (grp_bundle) {
                if (grp_bundle.iptv) {
                  m2mFlag = true
                }
              })
            }
          }
        })
        if (vm.bundle[i].iptv) {
          m2mFlag = true
          id = vm.bundle[i].bundle_id
        }
        if (vm.addonbundle) {
          for (var j = 0; j < vm.addonbundle.length; j++) {
            if (vm.addonbundle[j].iptv) {
              m2mFlag = true
              id = vm.addonbundle[j].bundle_id
            }
          }
        }
      }
      if (!m2mFlag) {
        this.ncf = ''
      }
      return { m2mFlag: m2mFlag, id: id }
    },
    formReady () {
      return !this.orgErrors.length &&
             !this.feedDomainErrors.length &&
             !this.bundleErrors.length &&
             !this.appSignatureErrors.length &&
             !this.smsHostErrors.length &&
             !this.smscontactErrors.length &&
             !this.smsMethodErrors.length &&
             !this.smsApiErrors.length &&
             !this.smsTemplateKeyErrors.length &&
             !this.languageErrors.length &&
             !this.moqSlabNameErrors.length &&
             !this.moqCostErrors.length &&
             !this.moqDurationErrors.length &&
             !this.apiGetPayErrors.length &&
             !this.apiPayStatusErrors.length
    },
    fields () {
      return [
        {
          name: 'sno',
          title: 'S.NO',
          width: '6%',
        },
        {
          name: 'org_name',
          title: 'Organization Name',
        },
        {
          name: 'feed_domain',
          title: 'Edge Domain',
        },
        {
          name: 'package_name',
          title: 'App Signature',
        },
        {
          name: '__slot:allow_notification',
          title: 'Allow Notification',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        },
      ]
    },
    filteredData () {
      return search(this.term, this.opertorSettingData)
    },
  },
  methods: {
    billingDateCheck () {
      // Add your billing date validation logic here if needed
    },
    checkRemoval () {
      this.check_active_pre_activation = false
      this.count = 0
      this.removed_flag_count = 0
      var vm = this
      if (this.pre_activation.length <= 1) {
        this.check_active_pre_activation = true
      } else {
        this.pre_activation.map(function (pre_data, i) {
          if (!pre_data.removed_flag) {
            vm.removed_flag_count++
            if (pre_data.bundle_name != '') {
              vm.count++
            }
          }
          if (i + 1 == vm.pre_activation.length) {
            if (vm.removed_flag_count == 1) {
              vm.check_active_pre_activation = true
            }
          }
        })
      }
      return this.check_active_pre_activation
    },
    dateCheck () {
      var obj = {
        'One Month': 29,
        'Three Month': 89,
        'Six Month': 179,
        'Twelve Month': 364,
      }

      this.pre_activation.map(function (activation) {
        var days = (obj[activation.mode.id])
        var start_date = new Date(activation.start_date)
        var expiryDate = new Date(start_date.setDate(start_date.getDate() + days)).setHours(23, 59, 59, 999)
        var end = new Date(expiryDate)
        if (end == 'Invalid Date') {
          // Vue.notify({ text: 'Please select the mode', type: 'error' })
          return activation.end_date = ''
        } else {
          return activation.end_date = end
        }
      })
    },
    removeField (index, type) {
      if (type == 'custom') this.customFields = this.customFields.filter((_field, i) => i != index)
      if (type == 'pre_activation') this.pre_activation = this.pre_activation.filter((_field, i) => i != index)
      this.checkRemoval()
    },
    removePaymentField (index) {
      this.paymentFields = this.paymentFields.filter((_field, i) => i != index)
    },
    removeTechField (index) {
      this.technicalFields = this.technicalFields.filter((_field, i) => i != index)
    },
    auth (auth_method) {
      this.smsFlag = true
    },
    getMoqBundle (data) {
      this.$http.get(config.menu.host + '/operator_setting/oper/' + data.bundle_id).then(resp => {
        var body = resp.body.bundlepackages ? resp.body.bundlepackages : resp.body.bundle_packages
        body.map(item => {
          if (item.package_name) {
            this.packagess.push(item.package_name)
          } else {
            this.packagess.push(item.provider_package.name)
          }
        })
      })

      var role = this.$cookies.get('userRole')
      if ((role == 'ADMIN') || (role == 'SUPPORT') || (role == 'ADVANCED_SUPPORT')) {
        this.moqbundle = ''
      }
      this.moqbundleArr = data
    },
    checkBundleArray (bundleData) {
      var bundle_ids = []
      bundleData.map(item => {
        if (this.packagess.includes(item.bundle_name)) {
          bundle_ids.push(item.bundle_name)
          Vue.notify({ text: 'This channel is already added in that selected bundle!!', type: 'error' })
        }
      })
      this.addonbundle = bundleData.filter(data => bundle_ids.indexOf(data.bundle_name) == -1)
    },
    billingDateCheck () {
      var checkdate = new Date(new Date(this.service_date).setDate(new Date(this.service_date).getDate() + 5)).getTime()
      if (checkdate <= new Date(this.billing_date).getTime()) {
        Vue.notify({ text: 'Billing date should be greater than the service date', type: 'error' })
        return this.billing_date = ''
      }
    },
    activationCall () {
      this.preActivationModal = true
      if (this.isCreate && this.pre_activation[0].start_date == '') {
        this.pre_activation = [{
          bundle_name: '',
          mode: '',
          start_date: '',
          end_date: '',
          free_count: 1,
          grace_period: 0,
          pre_bundle_cost: '',
          used_count: 0,
          removed_flag: false,
        }]
      };
      this.checkRemoval()

      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      this.disabledDate.to = yesterday
      var arr = []
      this.pre_activation.map(function (map_data) {
        if (map_data.removed_flag) {
          arr.push(map_data)
        };
      })
      if ((arr.length === this.pre_activation.length) && this.isUpdate) {
        this.pre_activation.push({
          bundle_name: '',
          mode: '',
          start_date: '',
          end_date: '',
          free_count: 1,
          grace_period: 0,
          pre_bundle_cost: '',
          used_count: 0,
          removed_flag: false,
        })
      };
    },
    finish () {
      var vm = this
      this.pre_activation.map(function (map) {
        if (map.bundle_name && (map.start_date == '' || map.end_date == '' || map.mode == '' || map.pre_bundle_cost == '') && !map.removed_flag) {
          Vue.notify({ text: 'Fill the form correctly', type: 'error' })
          return vm.preActivationModal = true
        } else if (map.bundle_name == '' && !map.removed_flag) {
          Vue.notify({ text: 'Fill the form correctly', type: 'error' })
          return vm.preActivationModal = true
        } else if ((map.pre_bundle_cost <= 0 || map.pre_bundle_cost == '' || !REGEX.Number(map.pre_bundle_cost)) && !map.removed_flag) {
          Vue.notify({ text: 'Fill the form correctly', type: 'error' })
          return vm.preActivationModal = true
        } else if ((map.free_count < 0 || map.grace_period < 0) && !map.removed_flag) {
          Vue.notify({ text: 'Negative values are not allowed', type: 'error' })
          return vm.preActivationModal = true
        } else {
          vm.preActivationModal = false
        }
      })
    },
    cancel () {
      this.preActivationModal = false
      this.showPreRemoveModal = false
      if (this.isUpdate) this.pre_activation = this.del_preactivation
    },
    getAllOperatorSetting () {
      var role = this.$cookies.get('userRole')
      if ((role == 'ADMIN') || (role == 'SUPPORT') || (role == 'ADVANCED_SUPPORT')) {
        this.adminRole = true
        this.createProvision = true
      }
      var ref = this
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/operator_setting').then(response => {
        loader.hide()
        let index = 0
        this.opertorSettingData = response.body.map(function (item) {
          ref.OrgArr = ref.OrgArr.filter(function (org_item, i) {
            org_item.id = i + 1
            if (org_item.org_id !== item.org_id) {
              if ((role == 'ADMIN') || (role == 'ADVANCED_SUPPORT') || (role == 'SUPPORT')) {
                return ((org_item.org_type == 'OPERATOR') || (org_item.org_type == 'RESELLER') || (org_item.org_type == 'HEPI_OPERATOR') || (org_item.org_type == 'HEPI_RESELLER'))
              } else {
                return ((org_item.org_type == 'OPERATOR') || (org_item.org_type == 'HEPI_OPERATOR'))
              }
            }
          })
          item.id = index++
          item.sno = index
          item.is_existing = true
          item.checkbox_value = false
          return item
        })
        if (role == 'RESELLER' || role == 'HEPI_RESELLER') {
          this.editFlag = false
          this.$http.get(config.menu.host + '/org').then(resp => {
            resp.body.map(function (thing) {
              if (thing.org_type == 'RESELLER' || thing.org_type == 'HEPI_RESELLER') {
                ref.createProvision = thing.sub_operators
              }
            })
          })
          this.opertorSettingData.map(function (arg) {
            var text = ''
            arg.addonbundle_backup = JSON.stringify(arg.addonbundle)
            if (typeof (arg.addonbundle) === 'object') {
              arg.addonbundle.map(function (pro) {
                if (text != '') {
                  text = text + ',' + pro.bundle_name
                } else {
                  text = pro.bundle_name
                }
              })
              arg.addonbundle = text
            }
          })
          this.opertorSettingData = this.opertorSettingData.filter(function (arg) {
            var str = ''; var text = ''
            arg.bundle_backup = JSON.stringify(arg.bundle)
            if (typeof (arg.bundle) === 'object') {
              arg.bundle.map(function (prop) {
                if (str != '') {
                  str = str + ',' + prop.bundle_name
                } else {
                  str = prop.bundle_name
                }
              })
              arg.bundle = str
            }
            return (arg.type == 'OPERATOR' || arg.type == 'HEPI_OPERATOR')
          })
        } else {
          this.opertorSettingData = response.body
        }
        this.isshowgrid = true
      })
    },
    createOperatorSetting () {
      this.orgErrors = this.org_name ? [] : ['Organization Name is required']
      if (!this.pre_activation_flag && this.operatorFlag) this.bundleErrors = (this.bundle && ((this.bundle.bundle_name) || (this.bundle.length > 0))) ? [] : ['Bundle is required']
      // if (!this.operatorFlag) this.bundleErrors = (this.bundle.length > 0) ? [] : ['Bundle is required']

      this.languageErrors = (this.language.length > 0) ? [] : ['Language is required']
      this.feedDomainErrors = this.feed_domain ? [] : ['Feed Domain is required']
      this.appSignatureErrors = this.package_name ? [] : ['App Signature is required']
      this.smsHostErrors = this.sms_host ? [] : ['SMS Host is required']
      this.smsMethodErrors = this.sms_method ? [] : ['SMS Method is required']
      this.smsApiErrors = this.sms_api_context ? [] : ['SMS API is required']
      this.smscontactErrors = this.sms_property ? [] : ['SMS Contact Key is required']
      this.smsTemplateKeyErrors = this.sms_template_key ? [] : ['SMS Template Key is required']
      this.moqDurationErrors = this.moq_duration.duration ? [] : ['MOQ Duration is required']
      this.moqCostErrors = this.moq_cost ? [] : ['MOQ Cost is required']
      this.moqSlabNameErrors = this.moq_slab_name ? [] : ['MOQ Slab Name is required']
      if (this.org_name.org_type == 'HEPI_OPERATOR') {
        this.phoneNoErrors = this.phone_no ? [] : ['Phone Number is required']
        this.stbCountErrors = this.stb_count ? [] : ['STB Count is required']
        this.dueDateErrors = this.due_day ? [] : ['Due Date is required']
        if ((this.phone_no.length != 10) || !REGEX.Number(this.phone_no)) {
          return Vue.notify({ text: 'Whatsapp Number is not valid!', type: 'error' })
        }
        if (this.billing_date.length == 0) {
          return Vue.notify({ text: 'Select Billing Date!', type: 'error' })
        }
        if (this.service_date.length == 0) {
          return Vue.notify({ text: 'Select Service Date!', type: 'error' })
        }
        if (this.due_day.length < 0) {
          return Vue.notify({ text: 'Enter Due Days!', type: 'error' })
        }
      }
      if (this.enable_bundle_creation || this.enable_reseller_bundle_creation) {
        this.apiGetPayErrors = this.api_get_payment_link ? [] : ['Api for Get Payment is required']
        this.apiPayStatusErrors = this.api_payment_link_status ? [] : ['Api for Payment Status is required']
      }
      if (!this.formReady) {
        return Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
      }
      if (this.allow_operator_content && !this.reference_name) {
        return Vue.notify({ text: 'Please enter reference name', type: 'error' })
      }
      if (this.allow_essentials && !this.essential_msg) {
        return Vue.notify({ text: 'Please enter Message', type: 'error' })
      }
      if (this.allow_operator_content && !REGEX.nameOnlyRegex(this.reference_name)) {
        return Vue.notify({ text: 'Only Lower Case & Upper Case is allowed for reference name', type: 'error' })
      }
      if (this.language.length > 4) {
        return Vue.notify({ text: 'Only 4 language is maximum', type: 'error' })
      }
      if ((this.enable_bundle_creation || this.enable_reseller_bundle_creation) && (this.paymentFields[0].key == '')) {
        return Vue.notify({ text: 'Please fill the payment details correctly', type: 'error' })
      }
      if (this.pre_activation_flag) {
        var addBundle = []; var check_bundle = []
        this.pre_activation.map(function (add_data) {
          if (add_data.bundle_name !== '') {
            addBundle.push(add_data.bundle_name)
            check_bundle.push(add_data)
          }
        })
        if (addBundle.length == 0) {
          return Vue.notify({ text: 'Select atleast one Pre-activation bundle', type: 'error' })
        }
        if (this.enable_reseller_bundle_creation) {
          this.bundle = [...this.bundle, ...addBundle]
          this.bundleArr = [...this.bundleArr, ...addBundle]
        }
      }
      var technical_data = []
      var technical_flag = true
      var allow = true

      if (allow && technical_flag) {
        var payload = {
          org_name: this.org_name.org_name,
          short_code: this.org_name.short_code,
          org_id: this.org_name.org_id,
          bundle: this.bundle,
          addonbundle: this.addonbundle,
          moqbundle: this.moqbundle,
          feed_domain: this.feed_domain,
          package_name: this.package_name,
          sms_host: this.sms_host,
          sms_property: this.sms_property,
          sms_method: this.sms_method.id ? this.sms_method.id : this.sms_method,
          sms_api_context: this.sms_api_context,
          sms_template: this.sms_template,
          custom_fields: this.customFields,
          allow_notification: this.allow_notification,
          allow_operator_content: this.allow_operator_content,
          allow_essentials: this.allow_essentials,
          essential_msg: this.essential_msg,
          reference_name: this.reference_name,
          ncf: Number(this.ncf),
          m2mFlag: this.checkIptv.m2mFlag,
          id: this.checkIptv.id,
          sms_template_key: this.sms_template_key,
          language: this.language,
          request_type: this.request_type,
          external_apps: this.external_apps,
          allow_for_subscription: this.allow_for_subscription,
          discount: Number(this.discount),
          enable_bundle_creation: this.enable_bundle_creation,
          payment_fields: this.paymentFields,
          api_get_payment_link: this.api_get_payment_link,
          api_payment_link_status: this.api_payment_link_status,
          moq_slab_name: this.moq_slab_name,
          moq_cost: this.moq_cost,
          moq_duration: this.moq_duration.duration,
          moq_carry_forward: this.moq_carry_forward,
          enable_reseller_bundle_creation: this.enable_reseller_bundle_creation,
          whatsapp_number: this.phone_no,
          pre_activation_flag: this.pre_activation_flag,
          allowed_stb_count: this.stb_count ? this.stb_count : 0,
          invoice_due_day: this.due_day ? this.due_day : 0,

        }
        if (this.billing_date) {
          payload.billing_date = this.billing_date
        }
        if (this.service_date) {
          payload.service_date = this.service_date
        }

        if (this.pre_activation_flag) payload.pre_activation = check_bundle
        if (this.ncf || this.checkIptv.m2mFlag) {
          payload.ncf_flag = true
        }
        if (this.enable_reseller_bundle_creation && this.is_fixed_rate) {
          payload.is_fixed_rate = this.is_fixed_rate
        }
        payload.technical_data = technical_data
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.post(config.menu.host + '/operator_setting', payload).then(responseData => {
          loader.hide()
          if (responseData && responseData.body) {
            Vue.notify({ text: responseData.body, type: 'success' })
          }
          this.list()
        }, errResponse => {
          loader.hide()
          if (errResponse && errResponse.body) {
            Vue.notify({ text: errResponse.body, type: 'error' })
          }
        })
      }
    },
    getAllowSubscription (data) {
      if (!data) {
        this.allow_for_subscription = data
      }
    },
    getReseller (org) {
      if (org.org_type == 'HEPI_OPERATOR') {
        this.iswhatsappno = true
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        this.disabledDate.to = yesterday
      }
      var role = this.$cookies.get('userRole')
      if (this.org_name == '') {
        this.addonbundle = []
      }
      this.enableOpFlag = true
      this.ifResellerFlag = false
      this.reference_name = ''
      this.org_type = org.org_type
      var addarr = []; var basearr = []; var hepiAddArr = []
      this.addonbundleArr = this.addonbundleArrbk
      this.addonbundleArr.map(function (iter, i) {
        if (iter.iptv && org.enable_iptv) {
          if ((iter.bundlepackages && iter.bundlepackages.length > 0) && iter.bundlepackages[0].provider_id == org.provider_id) {
            if ((org.org_type == 'HEPI_OPERATOR' || org.org_type == 'HEPI_RESELLER')) {
              if (iter.bundle_name.includes('A-la-carte')) {
                hepiAddArr.push({ id: i + 1, bundle_id: iter.bundle_id, bundle_name: iter.bundle_name })
              }
            } else {
              if (iter.iptv_type != 'Dpo' && iter.bundlepackages.length > 0) {
                addarr.push({ id: i + 1, bundle_id: iter.bundle_id, bundle_name: iter.bundle_name, iptv: iter.iptv })
              }
            }
          }
        } else {
          if (!iter.iptv && iter.iptv_type != 'Dpo' && iter.bundlepackages.length > 0) {
            addarr.push({ id: i + 1, bundle_id: iter.bundle_id, bundle_name: iter.bundle_name, iptv: iter.iptv })
          }
        }
      })
      if ((org.org_type == 'HEPI_OPERATOR' || org.org_type == 'HEPI_RESELLER')) {
        this.addonbundleArr = hepiAddArr
      } else { this.addonbundleArr = addarr }
      this.bundleArr.map(function (inter, i) {
        if (inter.iptv && org.enable_iptv) {
          if ((inter.bundlepackages && inter.bundlepackages.length > 0) && inter.bundlepackages[0].provider_id == org.provider_id) {
            if (org.org_type == 'HEPI_OPERATOR' || org.org_type == 'HEPI_RESELLER') {
              if (inter.bundle_type == 'groupedpackage') {
                basearr.push({ id: i + 1, bundle_id: inter.bundle_id, bundle_name: inter.bundle_name, iptv: inter.iptv })
              }
            } else {
              basearr.push({ id: i + 1, bundle_id: inter.bundle_id, bundle_name: inter.bundle_name, iptv: inter.iptv })
            }
          }
        } else {
          if (!inter.iptv && (inter.bundle_type !== 'groupedpackage')) {
            if (org.org_type == 'HEPI_OPERATOR' || org.org_type == 'HEPI_RESELLER') {
              if (inter.bundle_type == 'groupedpackage') {
                basearr.push({ id: i + 1, bundle_id: inter.bundle_id, bundle_name: inter.bundle_name, iptv: inter.iptv })
              }
            } else {
              basearr.push({ id: i + 1, bundle_id: inter.bundle_id, bundle_name: inter.bundle_name, iptv: inter.iptv })
            }
          } else {
            inter.id = i + 1
            if (org.org_type == 'HEPI_OPERATOR' || org.org_type == 'HEPI_RESELLER') {
              if (inter.bundle_type == 'groupedpackage') {
                basearr.push(inter)
              }
            } else {
              basearr.push(inter)
            }
          }
        }
      })
      if (org.org_type == 'HEPI_OPERATOR' || org.org_type == 'HEPI_RESELLER') {
        this.bundleArr = [...basearr, ...this.newHepiBundle]
      } else {
        this.bundleArr = basearr
      }
      if ((org.org_type == 'OPERATOR' || org.org_type == 'HEPI_OPERATOR') && org.reseller_org_id != null) {
        this.enable_res_flag = true
        this.moq_carry_flag = false
        this.operatorFlag = true
        this.allow_operator_content = false
        this.allow_essentials = false
        this.enableOpFlag = true
        this.$http.get(config.menu.host + '/operator_setting/' + org.reseller_org_id).then(resp => {
          this.$http.get(config.menu.host + '/bundle/global/new/pre_active').then(global_preactive_bundle => {
            if (resp.body) {
              global_preactive_bundle.body.map((obj, index) => {
                obj.id = index + 1
              })
              this.sms_host = resp.body.sms_host
              this.sms_method = resp.body.sms_method
              this.sms_property = resp.body.sms_property
              this.sms_api_context = resp.body.sms_api_context
              this.sms_template = resp.body.sms_template
              this.feed_domain = resp.body.feed_domain
              this.package_name = resp.body.package_name
              this.allow_notification = resp.body.allow_notification
              this.allow_operator_content = resp.body.allow_operator_content
              this.allow_essentials = resp.body.allow_essentials
              this.essential_msg = resp.body.essential_msg
              this.reference_name = resp.body.allow_operator_content ? data.reference_name : ''
              this.sms_template_key = resp.body.sms_template_key
              this.language = resp.body.language
              this.request_type = resp.body.request_type
              resp.body.custom_fields = resp.body.custom_fields.filter(function (item) {
                return (item.key != resp.body.sms_template_key)
              })
              this.customFields = resp.body.custom_fields
              this.paymentFields = resp.body.payment_fields
              this.moqbundle = resp.body.moqbundle
              this.moqbundleArr = [resp.body.moqbundle]
              this.PreActivationBundleArr = global_preactive_bundle.body
              this.pre_activation_backup = global_preactive_bundle.body
              // this.PreActivationBundleArr = resp.body.bundle
              // this.pre_activation_backup = resp.body.bundle
              if (resp.body && !resp.body.enable_reseller_bundle_creation) {
                var data = resp.body
                this.bundle = data.bundle
                if (org.org_type == 'HEPI_OPERATOR') {
                  this.getMoqBundle(data.bundle)
                }
                this.addonbundle = data.addonbundle
                this.enable_reseller_bundle_creation = data.enable_reseller_bundle_creation
                var vm = this
                this.bundleArr.map(function (data) {
                  // vm.bundle.map(function (input) {
                  if (data.bundle_id == vm.bundle.bundle_id) {
                    vm.bundle.id = data.id
                  }
                  // })
                })
                this.addonbundleArr.map(function (data) {
                  vm.addonbundle.map(function (input) {
                    if (data.bundle_id == input.bundle_id) {
                      input.id = data.id
                    }
                  })
                })
              } else {
                if (resp.body.enable_reseller_bundle_creation == 1) {
                  this.api_get_payment_link = resp.body.api_get_payment_link
                  this.api_payment_link_status = resp.body.api_payment_link_status
                  this.paymentFields = (resp.body.payment_fields) ? resp.body.payment_fields : [{ key: '', value: '' }]
                  this.enable_reseller_bundle_creation = resp.body.enable_reseller_bundle_creation
                  this.ifResellerFlag = !!(resp.body.enable_reseller_bundle_creation)
                  if ((role == 'ADMIN') || (role == 'SUPPORT') || (role == 'ADVANCED_SUPPORT')) {
                    this.$http.get(config.menu.host + '/operator_setting/reseller/' + resp.body.reseller_org_id).then(org => {
                      this.$http.get(config.menu.host + '/bundle/get/reseller/' + org.body.org_id).then(respo => {
                        if (respo.body.length == 0) {
                          return Vue.notify({ text: 'No Reseller Custom Bundle found', type: 'error' })
                        }
                        var arr = []; var addon_Arr = []
                        respo.body.map(function (bundle, i) {
                          bundle.id = i + 1
                          if (bundle.reseller_bundle_type == 'Base Bundle') {
                            arr.push(bundle)
                          } else {
                            if (bundle.iptv_type != 'Dpo') {
                              addon_Arr.push(bundle)
                            }
                          }
                        })
                        this.bundleArr = [...arr, ...this.newHepiBundle]
                        this.addonbundleArr = addon_Arr
                      })
                    })
                  } else {
                    this.$http.get(config.menu.host + '/bundle/get/reseller').then(response => {
                      if (response.body.length == 0) {
                        return Vue.notify({ text: 'No Custom Bundle found', type: 'error' })
                      }
                      var arr = []; var addon_Arr = []
                      response.body.map(function (bundle, i) {
                        bundle.id = i + 1
                        if (bundle.reseller_bundle_type == 'Base Bundle') {
                          arr.push(bundle)
                        } else {
                          if (bundle.iptv_type != 'Dpo') {
                            addon_Arr.push(bundle)
                          }
                        }
                      })
                      this.bundleArr = arr
                      this.addonbundleArr = addon_Arr
                    })
                  }
                }
              }
            } else {
              this.org_name = ''
              return Vue.notify({ text: 'No Operator setting available for your reseller', type: 'error' })
            }
          })
        })
      } else if (org.org_type == 'RESELLER' || org.org_type == 'HEPI_RESELLER') {
        this.moq_carry_flag = true
        this.enable_res_flag = false
        this.enableOpFlag = false
        this.$http.get(config.menu.host + '/operator_setting/' + org.reseller_org_id).then(resp => {
          this.enable_reseller_bundle_creation = resp.body.enable_reseller_bundle_creation
          if (!resp.body.enable_reseller_bundle_creation) {
            this.ifResellerFlag = true
            var vm = this
            this.$http.get(config.menu.host + '/bundle/get/addon').then(res => {
              if (res.body) {
                res.body.map(function (item) {
                  if (item.provider_name) {
                    vm.addonbundle = item.bundle_name
                  }
                })
              }
            })
          }
        })
      } else {
        this.operatorFlag = false
        // this.moq_carry_flag = true
      }
    },
    updateOperatorSetting () {
      var duration = this.moq_duration.duration ? this.moq_duration.duration : this.moq_duration
      this.moqDurationErrors = duration ? [] : ['MOQ Duration is required']
      this.moqCostErrors = this.moq_cost ? [] : ['MOQ Cost is required']
      this.moqSlabNameErrors = this.moq_slab_name ? [] : ['MOQ Slab Name is required']
      if (this.role_type == 'HEPI_OPERATOR') {
        this.phoneNoErrors = this.phone_no ? [] : ['Phone Number is required']
        this.stbCountErrors = this.stb_count ? [] : ['STB Count is required']
        this.dueDateErrors = this.due_day ? [] : ['Due Date is required']
        if ((this.phone_no.length != 10) || !REGEX.Number(this.phone_no)) {
          return Vue.notify({ text: 'Whatsapp Number is not valid!', type: 'error' })
        }
        if (this.billing_date.length == 0) {
          return Vue.notify({ text: 'Select Billing Date!', type: 'error' })
        }
        if (this.service_date.length == 0) {
          return Vue.notify({ text: 'Select Service Date!', type: 'error' })
        }
        if (this.due_day.length < 0) {
          return Vue.notify({ text: 'Enter Due Days!', type: 'error' })
        }
      }
      if (this.enable_bundle_creation || this.enable_reseller_bundle_creation) {
        this.apiGetPayErrors = this.api_get_payment_link ? [] : ['Api for Get Payment is required']
        this.apiPayStatusErrors = this.api_payment_link_status ? [] : ['Api for Payment Status is required']
      }
      if (!this.formReady) {
        return Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
      }
      var allow = true
      if (this.allow_operator_content && this.allow_operator_content && !this.reference_name) {
        return Vue.notify({ text: 'Please enter reference name', type: 'error' })
      }
      if (this.allow_essentials && !this.essential_msg) {
        return Vue.notify({ text: 'Please enter Message', type: 'error' })
      }
      if (this.allow_operator_content && !REGEX.nameOnlyRegex(this.reference_name)) {
        return Vue.notify({ text: 'Only Lower Case & Upper Case is allowed for reference name', type: 'error' })
      }
      if (this.language.length == 0) {
        return Vue.notify({ text: 'Please select language', type: 'error' })
      }
      if (this.language.length > 4) {
        return Vue.notify({ text: 'Only 4 language is maximum', type: 'error' })
      }
      if ((this.enable_bundle_creation || this.enable_reseller_bundle_creation) && (this.paymentFields[0].key == '')) {
        return Vue.notify({ text: 'Please fill the payment details correctly', type: 'error' })
      }
      if (this.bundle.length == 0) {
        return Vue.notify({ text: 'Please select bundle', type: 'error' })
      }

      var technical_data = []
      var technical_flag = true
      if (this.technicalFields[0].provider) {
        this.technicalFields.map(function (data) {
          if (data.brand && data.upstream) {
            technical_data.push({
              brand: data.brand,
              provider_id: data.provider_id,
              upstream: data.upstream,
              failover_upstream: data.failover_upstream,
            })
          } else if (data.provider.brand && data.upstream) {
            technical_data.push({
              brand: data.provider.brand,
              provider_id: data.provider.provider_id,
              upstream: data.upstream,
              failover_upstream: data.failover_upstream,
            })
          } else {
            technical_flag = false
            return Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
          }
        })
      }
      var addBundle = []; var vm = this
      if (this.pre_activation_flag) {
        var new_arr = []; var old_bundle_ids = []; var arr_data = []
        if (this.del_preactivation !== null) {
          if (vm.del_preactivation && vm.del_preactivation.length > 0) {
            vm.del_preactivation.map(function (val) { old_bundle_ids.push(val.bundle_name.bundle_id) })
            vm.del_preactivation.map(function (old) {
              var flag = false
              vm.pre_activation.map(function (newly) {
                if ((old.bundle_name.bundle_id == newly.bundle_name.bundle_id) && !newly.removed_flag) {
                  flag = true
                }
              })
              if (flag == true) {
                new_arr.push(old)
              } else {
                old.removed_flag = true
                new_arr.push(old)
              }
            })
          }
          this.pre_activation = (this.del_preactivation && this.del_preactivation.length > 0) ? new_arr : this.pre_activation
          if (vm.del_preactivation && vm.del_preactivation.length > 0) {
            vm.pre_activation.map(function (data) {
              if (!old_bundle_ids.includes(data.bundle_name.bundle_id)) {
                this.pre_activation.push(data)
              }
            })
          }
        }
        var check_active_entry = false
        this.pre_activation.map(function (check) {
          if (check.bundle_name !== '' && !check.removed_flag) {
            if (!check.removed_flag) {
              check_active_entry = true
            }
            arr_data.push(check)
            vm.bundle.map(function (bundle) {
              if (check.bundle_name.bundle_id != bundle.bundle_id) {
                addBundle.push(check.bundle_name)
              }
            })
          }
        })
        if (!check_active_entry && this.pre_activation.length > 0 && (this.pre_activation[0].bundle_name != '')) {
          check_active_entry = true
        }

        if (!check_active_entry) {
          return Vue.notify({ text: 'Select atleast one Pre-activation bundle', type: 'error' })
        }
      }
      var final_arr = []
      if (this.bundle.length > 0) {
        this.bundle = [...this.bundle, ...addBundle]
        this.bundle.map(function (item) {
          if (!final_arr.some(function (val) { return (val.bundle_id == item.bundle_id) })) {
            final_arr.push(item)
          }
        })
        this.bundle = final_arr
      }
      if (this.enable_reseller_bundle_creation) this.bundle
      this.is_fixed_rate = !!((this.enable_reseller_bundle_creation && this.is_fixed_rate))

      if (allow && technical_flag) {
        if ((!this.pre_activation_flag && this.old_pre_activation_flag) && !this.showPreRemoveModal) {
          this.pre_title = 'Remove STB Pre-activation'
          this.pre_message = 'Your existing pre-activation will be removed. Are you sure to disable the Pre-activation ?'
          this.showPreRemoveModal = true
        } else {
          if (this.bundle !== this.already_updated && this.org_type == 'HEPI_OPERATOR') {
            this.showConfirmationModal = true
          } else if (this.bundle == this.already_updated || this.org_type == 'OPERATOR' || this.org_type == 'RESELLER' || this.org_type == 'HEPI_RESELLER') {
            this.updateNow()
          }
        }
      }
    },
    updateNow () {
      var duration = this.moq_duration.duration ? this.moq_duration.duration : this.moq_duration
      var vm = this
      var technical_data = []
      var technical_flag = true
      if (this.technicalFields[0].provider) {
        this.technicalFields.map(function (data) {
          if (data.brand && data.upstream) {
            technical_data.push({
              brand: data.brand,
              provider_id: data.provider_id,
              upstream: data.upstream,
              failover_upstream: data.failover_upstream,
            })
          } else if (data.provider.brand && data.upstream) {
            technical_data.push({
              brand: data.provider.brand,
              provider_id: data.provider.provider_id,
              upstream: data.upstream,
              failover_upstream: data.failover_upstream,
            })
          } else {
            technical_flag = false
            return Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
          }
        })
      }
      var arr_data = []; var addBundle = []
      if (this.pre_activation && this.pre_activation.length > 0) {
        this.pre_activation.map(function (check) {
          if (check.bundle_name !== '' && !check.removed_flag) {
            delete check.bundle_name.zee_channel
            delete check.bundle_name.pay_channel
            delete check.bundle_name.fta_channel
            delete check.bundle_name.channel
            delete check.bundle_name.bundlepackages
            delete check.bundle_name.bundle_external_packages
            delete check.bundle_name.bundle_custom_packages
            delete check.bundle_name.bundle_custom_external_packages
            arr_data.push(check)
            vm.bundle.map(function (bundle) {
              if (check.bundle_name.bundle_id != bundle.bundle_id) {
                addBundle.push(check.bundle_name)
              }
            })
          }
        })
      }
      if (this.bundle.length > 0) {
        this.bundle.map(function (item) {
          delete item.zee_channel
          delete item.pay_channel
          delete item.fta_channel
          delete item.channel
          delete item.bundlepackages
          delete item.bundle_external_packages
          delete item.bundle_custom_packages
          delete item.bundle_custom_external_packages
        })
      }
      if (this.addonbundle.length > 0) {
        this.addonbundle.map(function (item) {
          delete item.zee_channel
          delete item.pay_channel
          delete item.fta_channel
          delete item.bundlepackages
          delete item.bundle_external_packages
          delete item.bundle_custom_packages
          delete item.bundle_custom_external_packages
        })
      }
      var payload = {
        org_name: this.org_name,
        org_id: this.org_id,
        feed_domain: this.feed_domain,
        bundle: this.bundle,
        addonbundle: this.addonbundle,
        package_name: this.package_name,
        sms_property: this.sms_property,
        sms_method: this.sms_method.id ? this.sms_method.id : this.sms_method,
        sms_host: this.sms_host,
        sms_template: this.sms_template,
        sms_api_context: this.sms_api_context,
        custom_fields: this.customFields,
        allow_notification: this.allow_notification,
        allow_operator_content: this.allow_operator_content,
        reference_name: this.reference_name,
        allow_essentials: this.allow_essentials,
        essential_msg: this.essential_msg,
        sms_template_key: this.sms_template_key,
        language: this.language,
        moqbundle: this.moqbundle,
        request_type: this.request_type,
        external_apps: this.external_apps,
        allow_for_subscription: this.allow_for_subscription,
        discount: Number(this.discount),
        enable_bundle_creation: this.enable_bundle_creation,
        payment_fields: this.paymentFields,
        api_get_payment_link: this.api_get_payment_link,
        api_payment_link_status: this.api_payment_link_status,
        moq_slab_name: this.moq_slab_name,
        moq_cost: this.moq_cost,
        moq_duration: duration,
        moq_carry_forward: this.moq_carry_forward,
        enable_reseller_bundle_creation: this.enable_reseller_bundle_creation,
        whatsapp_number: this.phone_no,
        pre_activation_flag: this.pre_activation_flag,
        is_fixed_rate: this.is_fixed_rate,
        allowed_stb_count: this.stb_count ? this.stb_count : 0,
        invoice_due_day: this.due_day ? this.due_day : 0,
      }
      if (this.billing_date) {
        payload.billing_date = this.billing_date
      }
      if (this.service_date) {
        payload.service_date = this.service_date
      }
      payload.pre_activation = (arr_data.length > 0) ? arr_data : []
      if (this.ncf) {
        payload.ncf = Number(this.ncf)
        payload.ncf_flag = true
        payload.id = this.checkIptv.id
      } else {
        payload.ncf = 0
        payload.ncf_flag = false
      }
      payload.technical_data = technical_data
      if (!this.pre_activation_flag && this.old_pre_activation_flag) {
        if (payload.pre_activation.length > 0) {
          payload.pre_activation.map(function (pre) {
            pre.removed_flag = true
          })
        }
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/operator_setting', payload).then(resp => {
        loader.hide()
        if (resp && resp.body) {
          Vue.notify({ text: resp.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    add () {
      this.Role = Vue.$cookies.get('roles')
      this.title = 'Create Operator Setting'
      this.org_name = ''
      this.bundle = []
      this.addonbundle = []
      this.feed_domain = ''
      this.ad_operator_margin = ''
      this.ad_mso_margin = ''
      this.ad_sys_margin = ''
      this.concurrent_login = ''
      this.package_name = ''
      this.ncf = ''
      this.sms_host = ''
      this.sms_method = ''
      this.sms_property = ''
      this.sms_api_context = ''
      this.sms_template = ''
      this.sms_template_key = ''
      this.customFields = [{ key: '', value: '' }]
      this.paymentFields = [{ key: '', value: '' }]
      this.technicalFields = [{ provider: '', upstream: '', failover_upstream: '' }]
      this.reference_name = ''
      this.moqbundle = ''
      this.allow_essentials = false
      this.essential_msg = ''
      this.discount = ''
      this.request_type = 'http'
      this.ncfErrors = []
      this.language = []
      this.moqbundleArr = []
      this.moqbundleErrors = []
      // this.moq_slab_list = []
      this.orgErrors = []
      this.bundleErrors = []
      this.feedDomainErrors = []
      this.operatorMarginErrors = []
      this.sysMarginErrors = []
      this.msoMarginErrors = []
      this.orgErrors = []
      this.smsApiErrors = []
      this.smsMethodErrors = []
      this.smsHostErrors = []
      this.smscontactErrors = []
      this.smsTemplateKeyErrors = []
      this.languageErrors = []
      this.discountErrors = []
      this.apiGetPayErrors = []
      this.api_get_payment_link = ''
      this.apiPayStatusErrors = []
      this.api_payment_link_status = ''
      this.moq_cost = 1
      this.moq_duration = ''
      this.moq_slab_name = ''
      this.moqCostErrors = []
      this.org_type = ''
      this.moq_carry_flag = false
      this.moq_carry_forward = false
      this.externalAppHostErrors = []
      this.externalAppTokenErrors = []
      this.externalAppPlanErrors = []
      this.externalAppCodeErrors = []
      this.phoneNoErrors = []
      this.iswhatsappno = ''
      this.external_apps = false
      this.allow_for_subscription = false
      this.enable_bundle_creation = false
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
      this.allow_notification = false
      this.allow_operator_content = false
      this.operatorFlag = false
      this.enable_reseller_bundle_creation = false
      this.ifResellerFlag = false
      this.phone_no = ''
      this.pre_activation_flag = false
      this.is_fixed_rate = false
      this.pre_activation = [{
        bundle_name: '',
        mode: '',
        start_date: '',
        end_date: '',
        free_count: 1,
        grace_period: 0,
        pre_bundle_cost: '',
        used_count: 0,
        removed_flag: false,
      }]
      this.service_date = new Date()
      this.billing_date = ''
      this.due_day = ''
      this.stb_count = ''
      this.dueDateErrors = []
      this.stbCountErrors = []
    },
    edit (row) {
      this.already_updated = row.bundle
      this.pre_activation = [{
        bundle_name: '',
        mode: '',
        start_date: '',
        end_date: '',
        free_count: 1,
        grace_period: 0,
        pre_bundle_cost: '',
        used_count: 0,
        removed_flag: false,
      }]
      this.Role = Vue.$cookies.get('roles')
      this.role_type = row.type
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/org/orgData/' + row.org_id + '/' + 'org_id' + '/null').then(resp => {
        loader.hide()
        if (resp.body.org_type == 'HEPI_OPERATOR') {
          this.iswhatsappno = true
        }
        this.org_type = resp.body.org_type
        this.operatorFlag = !!((resp.body.org_type == 'OPERATOR' || resp.body.org_type == 'HEPI_OPERATOR'))
        this.title = 'Update Operator Setting'
        this.org_id = row.org_id
        this.ncf = row.ncf
        this.ncf_flag = row.ncf_flag
        this.org_name = row.org_name
        if ((this.Role == 'ADMIN') || (this.Role == 'SUPPORT') || (this.Role == 'ADVANCED_SUPPORT')) {
          this.adminRole = true
          this.bundle = row.bundle
          this.addonbundle = row.addonbundle ? row.addonbundle : []
        } else {
          this.adminRole = false
          this.bundle = (JSON.parse(row.bundle_backup)) ? (JSON.parse(row.bundle_backup)) : []
          this.addonbundle = (JSON.parse(row.addonbundle_backup)) ? JSON.parse(row.addonbundle_backup) : []
        }
        this.del_preactivation = row.pre_activation
        var ActiveBundleArr = []
        if (row.pre_activation && row.pre_activation.length > 0) {
          row.pre_activation.map(function (data) {
            ActiveBundleArr.push(data.bundle_name)
            data.fieldFlag = !!(data.bundle_name)
            if (data.used_count == '' || data.used_count == null) data.used_count = 0
          })
          this.pre_activation = row.pre_activation
        }
        this.pre_activation_flag = row.pre_activation_flag
        this.old_pre_activation_flag = row.pre_activation_flag

        var addarr = []; var basearr = []; var hepiAddArr = []; var vm = this
        if ((row.enable_reseller_bundle_creation && (row.type == 'OPERATOR' || row.type == 'HEPI_OPERATOR')) && ((this.Role == 'ADMIN') || (this.role == 'SUPPORT') || (this.Role == 'ADVANCED_SUPPORT'))) {
          this.$http.get(config.menu.host + '/operator_setting/' + row.reseller_org_id).then(respo => {
            this.$http.get(config.menu.host + '/bundle/get/reseller/' + respo.body.org_id).then(bundle => {
              this.$http.get(config.menu.host + '/bundle/global/new/pre_active').then(global_preactive_bundle => {
                global_preactive_bundle.body.map((obj, index) => {
                  obj.id = index + 1
                })
                if (bundle.body.length == 0) {
                  Vue.notify({ text: 'No Reseller Custom Bundle found', type: 'error' })
                }
                var arr = []; var addon_Arr = []
                bundle.body.map(function (bundle, i) {
                  bundle.id = i + 1
                  if (bundle.reseller_bundle_type == 'Base Bundle') {
                    arr.push(bundle)
                  } else {
                    if (bundle.iptv_type != 'Dpo') {
                      addon_Arr.push(bundle)
                    }
                  }
                })
                this.bundleArr = arr
                this.bundleArr = [...this.bundleArr, ...ActiveBundleArr, ...this.newHepiBundle]
                this.bundleArr.map(function (data) {
                  vm.bundle.map(function (input) {
                    if (data.bundle_id == input.bundle_id) {
                      input.id = data.id
                    }
                  })
                })
                this.addonbundleArr = addon_Arr
                this.addonbundleArr.map(function (data) {
                  vm.addonbundle.map(function (input) {
                    if (data.bundle_id == input.bundle_id) {
                      input.id = data.id
                    }
                  })
                })
                this.PreActivationBundleArr = global_preactive_bundle.body
                this.pre_activation_backup = global_preactive_bundle.body
              })
            })
          })
        } else {
          this.$http.get(config.menu.host + '/bundle/global/new/pre_active').then(global_preactive_bundle => {
            global_preactive_bundle.body.map((obj, index) => {
              obj.id = index + 1
            })
            this.addonbundleArr = this.addonbundleArrbk
            this.bundleArr = this.bundleArrbk
            this.addonbundleArr.map(function (iter, i) {
              if (iter.iptv && resp.body.enable_iptv) {
                if ((iter.bundlepackages && iter.bundlepackages.length > 0) && iter.bundlepackages[0].provider_id == resp.body.provider_id) {
                  if ((resp.body.org_type == 'HEPI_OPERATOR' || resp.body.org_type == 'HEPI_RESELLER') && iter.bundle_name.includes('A-la-carte')) {
                    if (iter.bundle_name.includes('A-la-carte')) {
                      hepiAddArr.push({ id: i + 1, bundle_id: iter.bundle_id, bundle_name: iter.bundle_name })
                    }
                  } else {
                    if (iter.iptv_type != 'Dpo' && iter.bundlepackages.length > 0) {
                      addarr.push({ id: i + 1, bundle_id: iter.bundle_id, bundle_name: iter.bundle_name, iptv: iter.iptv })
                    }
                  }
                }
              } else {
                if (!iter.iptv && iter.iptv_type != 'Dpo' && iter.bundlepackages.length > 0) {
                  addarr.push({ id: i + 1, bundle_id: iter.bundle_id, bundle_name: iter.bundle_name, iptv: iter.iptv })
                }
              }
            })
            if ((resp.body.org_type == 'HEPI_OPERATOR' || resp.body.org_type == 'HEPI_RESELLER')) {
              this.addonbundleArr = hepiAddArr
            } else { this.addonbundleArr = addarr }
            this.bundleArr.map(function (inter, i) {
              if (inter.iptv && resp.body.enable_iptv) {
                if ((inter.bundlepackages && inter.bundlepackages.length > 0) && inter.bundlepackages[0].provider_id == resp.body.provider_id) {
                  if (resp.body.org_type == 'HEPI_OPERATOR' || resp.body.org_type == 'HEPI_RESELLER') {
                    if (inter.bundle_type == 'groupedpackage') {
                      basearr.push({ id: i + 1, bundle_id: inter.bundle_id, bundle_name: inter.bundle_name, iptv: inter.iptv })
                    }
                  } else {
                    basearr.push({ id: i + 1, bundle_id: inter.bundle_id, bundle_name: inter.bundle_name, iptv: inter.iptv })
                  }
                }
              } else {
                if (!inter.iptv) {
                  if (resp.body.org_type == 'HEPI_OPERATOR' || resp.body.org_type == 'HEPI_RESELLER') {
                    if (inter.bundle_type == 'groupedpackage') {
                      basearr.push({ id: i + 1, bundle_id: inter.bundle_id, bundle_name: inter.bundle_name, iptv: inter.iptv })
                    }
                  } else {
                    basearr.push({ id: i + 1, bundle_id: inter.bundle_id, bundle_name: inter.bundle_name, iptv: inter.iptv })
                  }
                }
              }
            })
            this.bundleArr = [...basearr, ...this.newHepiBundle]
            var vm = this
            this.bundleArr = (typeof (this.bundleArr) === 'string') ? JSON.parse(this.bundleArr) : this.bundleArr
            vm.bundle = (typeof (vm.bundle) === 'string') ? JSON.parse(vm.bundle) : vm.bundle
            if (vm.bundle.length > 0) {
              this.bundleArr.map(function (data) {
                vm.bundle.map(function (input) {
                  if (data.bundle_id == input.bundle_id) {
                    input.id = data.id
                  }
                })
              })
            }
            this.addonbundleArr = (typeof (this.addonbundleArr) === 'string') ? JSON.parse(this.addonbundleArr) : this.addonbundleArr
            vm.addonbundle = (typeof (vm.addonbundle) === 'string') ? JSON.parse(vm.addonbundle) : vm.addonbundle
            this.addonbundleArr.map(function (data) {
              vm.addonbundle.map(function (input) {
                if (data.bundle_id == input.bundle_id) {
                  input.id = data.id
                }
              })
            })
            this.PreActivationBundleArr = global_preactive_bundle.body
            this.pre_activation_backup = global_preactive_bundle.body
          })
        }
        if (this.org_type == 'HEPI_OPERATOR') {
          const today = new Date()
          const yesterday = new Date(today)
          yesterday.setDate(yesterday.getDate() - 1)
          this.disabledDate.to = yesterday
        }
        this.moq_slab_list = (row.moq_slab_list) ? (row.moq_slab_list) : []
        this.moq_cost = row.moq_cost
        this.moq_duration = (row.moq_duration) ? row.moq_duration : ''
        this.moq_slab_name = row.moq_slab_name
        this.api_get_payment_link = (row.api_get_payment_link) ? row.api_get_payment_link : ''
        this.api_payment_link_status = (row.api_payment_link_status) ? row.api_payment_link_status : ''
        this.moqbundle = (row.moqbundle) ? row.moqbundle : ''
        this.language = (row.language) ? row.language : []
        this.bundle_backup = row.bundle_backup
        this.addonbundle_backup = row.addonbundle_backup
        this.feed_domain = row.feed_domain
        this.ad_mso_margin = row.ad_mso_margin
        this.ad_operator_margin = row.ad_operator_margin
        this.ad_sys_margin = row.ad_sys_margin
        this.concurrent_login = row.concurrent_login
        this.package_name = row.package_name
        this.sms_method = row.sms_method
        this.sms_property = row.sms_property
        this.sms_host = row.sms_host
        this.sms_api_context = row.sms_api_context
        this.sms_template = row.sms_template
        this.request_type = row.request_type ? row.request_type : 'http'
        this.sms_template_key = row.sms_template_key
        this.allow_essentials = row.allow_essentials
        this.essential_msg = row.essential_msg
        this.phone_no = row.whatsapp_number
        this.is_fixed_rate = row.is_fixed_rate
        this.stb_count = row.allowed_stb_count
        this.due_day = row.invoice_due_day
        this.billing_date = row.billing_date ? row.billing_date : ''
        this.service_date = row.service_date ? row.service_date : ''
        var fields = row.custom_fields.filter(function (item) {
          return (item.key != row.sms_template_key)
        })
        if (row.technical_data && row.technical_data.length > 0) {
          row.technical_data.map(function (data) {
            data.showFlag = true
            data.provider = data.brand
          })
        }
        if (row.technical_data && row.technical_data.length > 0) {
          this.technicalFields = row.technical_data
        } else {
          this.technicalFields = [{ provider: '', upstream: '', failover_upstream: '' }]
        }
        this.customFields = fields
        this.paymentFields = (row.payment_fields) ? row.payment_fields : [{ key: '', value: '' }]
        this.external_apps = (row.external_apps) ? row.external_apps : false
        this.allow_for_subscription = (row.allow_for_subscription) ? row.allow_for_subscription : false
        this.isshowgrid = false
        this.isshowForm = true
        this.isCreate = false
        this.isUpdate = true
        this.allow_notification = row.allow_notification
        this.allow_operator_content = row.allow_operator_content
        this.reference_name = row.reference_name
        this.discount = row.discount
        this.moq_carry_forward = row.moq_carry_forward ? row.moq_carry_forward : false
        this.moq_carry_flag = !!((row.type == 'RESELLER' || row.type == 'HEPI_RESELLER'))
        this.enable_bundle_creation = row.enable_bundle_creation ? row.enable_bundle_creation : false
        this.enable_reseller_bundle_creation = row.enable_reseller_bundle_creation
        this.ifResellerFlag = !!((row.type == 'RESELLER' || row.type == 'HEPI_RESELLER'))
        this.enableOpFlag = !!((row.type == 'OPERATOR' || row.type == 'HEPI_OPERATOR'))
        this.enable_res_flag = !((row.type == 'RESELLER' || row.type == 'HEPI_RESELLER'))
        if (this.allow_operator_content) {
          this.operatorFlag = true
        }
        this.moqCostErrors = []
        this.moqDurationErrors = []
        this.moqSlabNameErrors = []
      }, errResponse => {
        loader.hide()
      })
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllOperatorSetting()
      this.appSignatureErrors = []
      this.moqSlabNameErrors = []
      this.moqDurationErrors = []
      this.moq_slab_list.length = []
      this.orgErrors = []
      this.bundleErrors = []
      this.feedDomainErrors = []
      this.smsHostErrors = []
      this.smsMethodErrors = []
      this.smscontactErrors = []
      this.smsApiErrors = []
      this.smsTemplateKeyErrors = []
      this.apiGetPayErrors = []
      this.apiPayStatusErrors = []
      this.phoneNoErrors = []
      this.stbCountErrors = []
      this.dueDateErrors = []
      this.showConfirmationModal = false
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>

<style scoped>
.text_box {
  width: 937px;
  height: 101px;
  border-color: black;
  border-radius: 10px;
  resize: none;
}

.fields {
  display: grid;
  grid-template-columns: 5fr 5fr 5fr 5fr 5fr 1fr;
  grid-gap: 1rem;
  margin-top: 2rem;
}

.content_width {
  width: 30px;
  height: 30px;
}

.input {
  width: 100%;
  height: 35px;
  cursor: default;
  text-align: -moz-center;
}

.errors {
  color: #ff0000;
  font-size: 12px;
}
</style>
